import React from "react";
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faTwitterSquare } from "@fortawesome/free-brands-svg-icons/faTwitterSquare";
import { faYoutubeSquare } from "@fortawesome/free-brands-svg-icons/faYoutubeSquare";
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons/faInstagramSquare";
import Section from "../components/Section";
import Logo from "../images/ENS_Logo.png";

const FooterNavItems = () => {
  return (
    <Section>
      <Row>
        <Col className="footer-logo-part" md={6} xs={6}>
          <Link className="nav-link" to="/">
            <img className="footer-logo img-fluid" src={Logo} alt="" />
          </Link>

          <Nav>
            <NavItem>
              <NavLink>Privacy Policy</NavLink>
            </NavItem>
          </Nav>
          <p>Copyright ENS Logistics LLC - 2020</p>
        </Col>
        <Col md={3} xs={6}>
          <Nav vertical>
            <NavItem>
              <NavLink tag={Link} to="/">
                Home
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={Link} to="/about">
                About
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={Link} to="/services">
                Services
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={Link} to="/working-with-ens">
                Working with ENS
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={Link} to="/#contact-us">
                Contact Us
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        {/* <Col md={3} className="contact-detail">
          <div className="social-icons">
            <a
              href="https://facebook.com/freightgenie"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitterSquare} />
            </a>
            <a
              href="https://twitter.com/GenieFreight"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
            <a
              href="https://linkedin.com/company/freightgenie"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagramSquare} />
            </a>
            <a
              href="https://linkedin.com/company/freightgenie"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutubeSquare} />
            </a>
          </div>
        </Col> */}
      </Row>
    </Section>
  );
};

const Footer = () => {
  return (
    <div className="footer">
      <FooterNavItems />
    </div>
  );
};

export default Footer;
