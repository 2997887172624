import React from "react";
import Section from "./Section";

const PageTitleSection = ({ title }) => {
  return (
    <Section className="page-title-section" container={false}>
      <h1>{title}</h1>
      <h1 className="line-before-after">
        <span>{title}</span>
      </h1>
      <h1>{title}</h1>
    </Section>
  );
};

export default PageTitleSection;
