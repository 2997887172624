import { Link } from "gatsby";
import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container
} from "reactstrap";
import NavBarLogo from "../images/ENS_Logo.png";
import useScrollPosition from "@react-hook/window-scroll";
import className from "classnames";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const scrollY = useScrollPosition();

  const navbarToggle = () => setIsOpen(!isOpen);
  let isHomepage, isMobile;

  if (typeof window !== "undefined") {
    isHomepage = window.location.pathname === "/";
    isMobile = window.matchMedia("(max-width: 576px)").matches;
  }
  const shouldChangeNavbarStyle = !isMobile && scrollY < 225 && isHomepage;

  return (
    <>
      <Navbar
        className={className({
          "navbar-inverse": shouldChangeNavbarStyle
        })}
        expand="md"
        sticky="top"
        light
      >
        <Container>
          <NavbarBrand tag={Link} to="/">
            <img
              className={className({
                "navbar-logo": shouldChangeNavbarStyle,
                "navbar-logo-small": !shouldChangeNavbarStyle
              })}
              src={NavBarLogo}
              alt=""
            />
          </NavbarBrand>
          <NavbarToggler onClick={navbarToggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink tag={Link} to="/">
                  Home
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} to="/about">
                  About
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} to="/services">
                  Services
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} to="/working-with-ens">
                  Working with ENS
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} to="/#contact-us">
                  Contact Us
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
